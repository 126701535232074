import {Component, Input, OnInit} from '@angular/core';
import {PoemModel} from '../../models/poem.model';

@Component({
  selector: 'app-poem',
  templateUrl: './poem.component.html',
  styleUrls: ['./poem.component.scss']
})
export class PoemComponent implements OnInit {

  @Input() poem: PoemModel;

  constructor() { }

  ngOnInit(): void {
  }
}
