import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';

// locale
import { registerLocaleData } from '@angular/common';
import localeLt from '@angular/common/locales/lt';
import localeLtExtra from '@angular/common/locales/extra/lt';
import { FireDatePipe } from './pipes/fire-date.pipe';
import { PoemComponent } from './components/poem/poem.component';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import { PoemListComponent } from './components/poem-list/poem-list.component';
registerLocaleData(localeLt, 'lt', localeLtExtra);

@NgModule({
  declarations: [
    AppComponent,
    FireDatePipe,
    PoemComponent,
    PoemListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
