import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fireDate'
})
export class FireDatePipe implements PipeTransform {

  transform(timestamp: any): Date {
    return timestamp.toDate();
  }
}
