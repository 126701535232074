import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {PoemModel} from '../models/poem.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PoemFireServiceService {

  private collectionPath = 'poezija';

   constructor(private db: AngularFirestore) { }

   public getPoems(): Observable<any> {
     return this.db.collection(this.collectionPath, ref =>
        ref.orderBy('date', 'desc')).valueChanges();
   }

 /* public getPoemsSnapshot(sort: string = 'desc') {
    let collection: AngularFirestoreCollection<PoemModel>;

    if (sort.trim().toLowerCase() === 'asc') {
      collection = this.db.collection(this.collectionPath, ref =>
        ref.orderBy('date'));
    } else if (sort.trim().toLowerCase() === 'desc') {
      collection = this.db.collection(this.collectionPath, ref =>
        ref.orderBy('date', 'desc'));
    } else {
      collection = this.db.collection(this.collectionPath);
    }
    return collection.snapshotChanges(); // .get();
  }*/

/*  public getPoemsPage(pageNumber: number, pageSize: number) {
     const first = this.db.collection(this.collectionPath, ref =>
      ref.orderBy('date', 'desc').limit(pageSize));
  }*/
}
