import { Component, OnInit } from '@angular/core';
import {PoemFireServiceService} from '../../services/poem-fire-service.service';
import {PoemModel} from '../../models/poem.model';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-poem-list',
  templateUrl: './poem-list.component.html',
  styleUrls: ['./poem-list.component.scss']
})
export class PoemListComponent implements OnInit {

  public poems: Array<PoemModel> = new Array<PoemModel>();

  constructor(private poemService: PoemFireServiceService) { }

  ngOnInit(): void {
    this.getPoems();
  }

  private getPoems() {
    this.poemService.getPoems().subscribe((data: Array<PoemModel>) => {
      this.poems = data;
    });
  }

/*  private getPoemsSnapshot() {
    this.poemService.getPoems().subscribe(data => {
      this.poems = data.map(e => {
        return {
          key: e.payload.doc.id,
          ...e.payload.doc.data()
        };
      });
    });
  }*/

}
